// Images file for 'veteranhomequiz.com'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Unselected from '../../../../src/images/unselected-circle-white.png';
import Selected from '../../../../src/images/selected-circle-white.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Background from './assets/bg.jpg';

export default {
  Favicon,
  Unselected,
  Selected,
  Dropdown,
  Background,
};
